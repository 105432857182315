<template>
  <span>
    <b-button
      @click="openModal"
      variant="primary"
      size="sm"
      class="p-0 px-2 ml-1"
      >Change Sticker</b-button
    >

    <b-modal
      :id="gen_id"
      title="Change sticker"
      @hidden="resetModal"
      hide-footer
    >
      <label class="typo__label">Single select</label>
      <multiselect
        v-model="form.value"
        placeholder="Please choose a sticker"
        :options="stickers"
        :option-height="104"
        :show-labels="false"
      >
        <template slot="singleLabel" slot-scope="props"
          ><img
            class="option__image"
            :src="props.option.sample_file"
            alt="Sample"
            width="84"
          /><span class="option__desc"
            ><span class="option__title">{{
              props.option.no_of_ads
            }}</span></span
          ></template
        >
        <template slot="option" slot-scope="props"
          ><img
            class="option__image"
            :src="props.option.sample_file"
            alt="Sample"
            width="84"
          />
          <div class="option__desc">
            Number of Ads
            <span class="option__title"
              >{{ props.option.no_of_ads }} <strong> </strong>
            </span>
          </div>
        </template>
      </multiselect>

      <div v-if="$v.form.value.$error" style="color: red">
        Please Choose a Sticker!
      </div>
      <div class="d-flex justify-content-end">
        <b-button
          class="mt-4"
          variant="primary"
          size="sm"
          @click="changeSticker"
        >
          <span v-if="changing">Submitting...</span>
          <span v-else> Submit </span>
        </b-button>
      </div>
    </b-modal>
  </span>
</template>

<script>
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import { campaigns } from "@/config/api/campaigns";
export default {
  props: ["data"],
  data() {
    return {
      form: {
        value: null,
      },
      changing: false,
      stickers: [],
    };
  },
  validations: {
    form: {
      value: { required },
    },
  },
  components: {
    Multiselect,
  },
  created() {
    this.loadAvailableStickers(this.data.campaign._id);
  },
  methods: {
    changeSticker() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const api = campaigns.changeStickerV2;
        api.data = {
          campaign_stickers: this.form.value,
          ad_id: this.data._id,
        };
        this.generateAPI(api)
          .then((res) => {
            this.$emit("change", res.data.ad);
            this.$bvModal.hide(`${this.gen_id}`);

            this.$bvToast.toast("Sticker is changed!", {
              title: "Sticker",
              variant: "success",
              solid: true,
            });
          })
          .catch((err) => {
            this.$bvToast.toast(
              `Sticker is not changed!, ${err.response.data.error}`,
              {
                title: "Sticker",
                variant: "danger",
                solid: true,
              }
            );
          });
      }
    },

    loadAvailableStickers(id) {
      const api = campaigns.getCampaignDesignV2;
      api.id = id;
      this.generateAPI(api).then((res) => {
        this.stickers = res.data.stickers;
      });
    },
    openModal() {
      this.$bvModal.show(`${this.gen_id}`);
    },
    resetModal() {
      this.form.value = null;
      this.submit = false;
    },
  },
  computed: {
    gen_id() {
      return `day-change-modal${this.data._id}`;
    },
  },
};
</script>

<style></style>
