<template>
  <Layout>
    <div class="vehicle-ad">
      <div class="d-flex justify-content-between p-2 pt-4">
        <FormulateForm class="d-flex" @submit="submitHandler">
          <FormulateInput class="mr-2" placeholder="Search" name="term" />
          <FormulateInput type="submit" label="Search" />
        </FormulateForm>
        <add-company-modal @reloadData="getAllUser" />
        <b-button
          variant="primary"
          style="height: 38px"
          size="sm"
          @click="refreshDriver"
        >
          <span v-if="!loading">Refresh</span> <span v-else>Loading...</span>
        </b-button>
      </div>
      <b-table
        :items="items"
        :fields="fields"
        :per-page="perPage"
        :busy="loading"
        striped
        responsive="sm"
        style="min-height: 640px"
        show-empty
      >
        <template #empty>
          <div class="text-center">Companies are not available!</div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(driver_info)="row">
          <div @click="row.toggleDetails" role="button">
            <strong>
              {{
                row.item.license ? row.item.license.full_name : "N/A"
              }}</strong
            >
            <br />
            {{ row.item.email || "No Email ID" }}<br />
            +{{ row.item.country_code }}-{{ row.item.phone_number }}
          </div>
        </template>

        <template #cell(vehicles)="row">
          <div v-if="row.value.length" >
            {{ row.value[0].make.name }} - {{ row.value[0].model.name }} (
            {{ row.value[0].manufacturing_year }}) <br />
            <strong
              >{{ row.value[0].plate_category }}
              {{ row.value[0].plate_number }}</strong
            >
          </div>
          <div v-else>No Vehicle</div>
        </template>
        <template #cell(badge)="row">
          <ul style="list-style: none; margin: 0; padding: 0">
            <li>
              <b-badge
                variant="success"
                v-if="
                  row.item.users_list &&
                  row.item.users_list.length &&
                  row.item.users_list[0].email
                "
              >
                Drivers Account
              </b-badge>
              <b-badge variant="danger" v-else> No Drivers Account </b-badge>
            </li>
          </ul>
        </template>

        <template #row-details="row">
          <b-card>
            <div class="details-section">
              <div class="row">
                <div class="col-4">Balance</div>
                <div class="col-8">{{ row.item.amount_to_be_paid }} SAR</div>
              </div>
            </div>

            <div v-if="row.item.license" class="details-section">
              <h6>License</h6>
              <div class="row mb-1">
                <div class="col-4">Name</div>
                <div class="col-8">
                  {{ row.item.license.full_name }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">License no.</div>
                <div class="col-8">
                  {{ row.item.license.license_number }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">Nationality</div>
                <div class="col-8">
                  {{ row.item.license.nationality }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">License Pic</div>
                <div class="col-8">
                  <a
                    class="bg-dark text-white px-2"
                    :href="row.item.license.license_front"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View
                  </a>

                  <span v-if="!row.item.license.license_front">
                    No image uploaded
                  </span>
                </div>
              </div>
            </div>
            <div v-else class="pl-2">No License Information</div>
            <div
              v-if="row.item.vehicles && row.item.vehicles.length"
              class="details-section"
            >
              <h6>Vehicle</h6>
              <div class="row mb-1">
                <div class="col-4">Sticker type</div>
                <div class="col-8">
                  {{ row.item.vehicles[0].sticker_type }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">Model</div>
                <div class="col-8">
                  {{ row.item.vehicles[0].model.name }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">Make</div>
                <div class="col-8">
                  {{ row.item.vehicles[0].make.name }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">Plate Category</div>
                <div class="col-8">
                  {{ row.item.vehicles[0].plate_category }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">Plate Number</div>
                <div class="col-8">
                  {{ row.item.vehicles[0].plate_number }}
                </div>
              </div>
            </div>
            <div v-else class="pl-2">No Vehicle Available</div>

            <div
              v-if="
                row.item.vehicles &&
                row.item.vehicles.length &&
                row.item.vehicles[0].current_ad
              "
              class="details-section"
            >
              <h6>Current Ad</h6>
              <div class="row mb-1">
                <div class="col-4">Campaign Name</div>
                <div class="col-8">
                  {{
                    row.item.vehicles[0].current_ad.campaign.campaign_name ||
                    "N/A"
                  }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">Start date</div>
                <div class="col-8">
                  <span v-if="row.item.vehicles[0].current_ad.start_date">
                    {{
                      moment(row.item.vehicles[0].current_ad.start_date).format(
                        "LLL"
                      )
                    }}
                  </span>
                  <span v-else> Not Started </span>
                </div>
              </div>
              <div
                class="row mb-1"
                v-if="row.item.vehicles[0].current_ad.start_date"
              >
                <div class="col-4">Expire At</div>
                <div class="col-8">
                  {{
                    moment(row.item.vehicles[0].current_ad.start_date)
                      .add(row.item.vehicles[0].current_ad.no_of_days, "days")
                      .format("LLL")
                  }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">No. of days</div>
                <div class="col-8">
                  {{ row.item.vehicles[0].current_ad.no_of_days }}
                  <change-days
                    v-on:change="dateChanged"
                    :data="{
                      ad_id: row.item.vehicles[0].current_ad._id,
                      is_current_ad: true,
                      id: row.item._id,
                      no_of_days: row.item.vehicles[0].current_ad.no_of_days,
                    }"
                  />
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">Sticker file</div>
                <div class="col-8">
                  <a
                    class="bg-dark text-white px-2"
                    :href="
                      row.item.vehicles[0].current_ad.campaign.sticker_file
                    "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View
                  </a>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">Sample file</div>
                <div class="col-8">
                  <a
                    :href="row.item.vehicles[0].current_ad.campaign.sample_file"
                    target="_blank"
                  >
                    <img
                      height="120px"
                      :src="
                        row.item.vehicles[0].current_ad.campaign.sample_file
                      "
                      alt=""
                      srcset=""
                    />
                  </a>
                </div>
              </div>
            </div>
            <div v-else class="pl-2">No Current Ad</div>

            <!-- -->
            <div
              v-if="
                row.item.vehicles &&
                row.item.vehicles.length &&
                row.item.vehicles[0].upcoming_ad
              "
              class="details-section"
            >
              <div class="d-flex justify-content-between">
                <h6>Upcoming Ad</h6>

                <change-sticker
                  @change="campaignStickerChanged"
                  :data="row.item.vehicles[0].upcoming_ad"
                />
              </div>

              <div class="row mb-1">
                <div class="col-4">Campaign Name</div>
                <div class="col-8">
                  {{
                    row.item.vehicles[0].upcoming_ad.campaign.campaign_name ||
                    "N/A"
                  }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">Start date</div>
                <div class="col-8">
                  <span v-if="row.item.vehicles[0].upcoming_ad.start_date">
                    {{
                      moment(
                        row.item.vehicles[0].upcoming_ad.start_date
                      ).format("LLL")
                    }}
                    <start-time-modal
                      :data="{
                        id: row.item.vehicles[0].upcoming_ad._id,
                        start_within:
                          row.item.vehicles[0].upcoming_ad.start_within,
                      }"
                    />
                  </span>
                  <span v-else> Not Started </span>
                </div>
              </div>
              <div
                class="row mb-1"
                v-if="row.item.vehicles[0].upcoming_ad.start_date"
              >
                <div class="col-4">Expire At</div>
                <div class="col-8">
                  {{
                    moment(row.item.vehicles[0].upcoming_ad.start_date)
                      .add(row.item.vehicles[0].upcoming_ad.no_of_days, "days")
                      .format("LLL")
                  }}
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">No. of days</div>
                <div class="col-8">
                  {{ row.item.vehicles[0].upcoming_ad.no_of_days }}
                  <change-days
                    v-on:change="dateChanged"
                    :data="{
                      id: row.item._id,
                      is_current_ad: false,
                      ad_id: row.item.vehicles[0].upcoming_ad._id,
                      no_of_days: row.item.vehicles[0].upcoming_ad.no_of_days,
                    }"
                  />
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">Sticker file</div>
                <div class="col-8">
                  <a
                    class="bg-dark text-white px-2"
                    :href="
                      row.item.vehicles[0].upcoming_ad.campaign.sticker_file
                    "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View
                  </a>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-4">Sample file</div>
                <div class="col-8">
                  <a
                    :href="
                      row.item.vehicles[0].upcoming_ad.campaign.sample_file
                    "
                    target="_blank"
                  >
                    <img
                      height="120px"
                      :src="
                        row.item.vehicles[0].upcoming_ad.campaign.sample_file
                      "
                      alt=""
                      srcset=""
                    />
                  </a>
                </div>
              </div>
            </div>
            <div v-else class="pl-2">No Upcoming Ad</div>

            <div class="d-flex justify-content-end my-2">
              <b-button variant="primary" size="sm" @click="row.toggleDetails"
                >Show Less</b-button
              >
            </div>
          </b-card>
        </template>
        <template #cell(action)="row">
          <b-dropdown
            variant="primary"
            text="Actions"
            class="m-md-2"
            size="sm"
            right
          >
            <b-dropdown-item @click="openUpdateModal(row)"
              >Update</b-dropdown-item
            >
            <b-dropdown-item @click="deleteCompany(row)"
              >Delete</b-dropdown-item
            >
            <b-dropdown-item @click="viewDrivers(row)"
              >View Drivers</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </b-table>
      <div class="d-flex justify-content-between p-2 px-4">
        <div>
          <label class="d-inline-flex align-items-center">
            Show&nbsp;
            <b-form-select
              v-model="perPage"
              @change="perPageChanged"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;{{ rows }}&nbsp;entries
          </label>
        </div>
        <b-pagination
          @change="pageChanged"
          :value="currentPage"
          :per-page="perPage"
          pills
          :total-rows="rows"
        ></b-pagination>
      </div>
      <b-modal id="modal1" ref="modal1" title="Update Company" hide-footer>
        <UpdateCompanyModal :company="companyData" @callback="closeModal" />
      </b-modal>
    </div>
  </Layout>
</template>

<script>
import AddCompanyModal from "@/components/company_vehicle/AddCompany";
import UpdateCompanyModal from "@/components/company_vehicle/UpdateCompany";
import Layout from "@/views/layouts/main";
import { vehicle_company } from "@/config/api/vehicle_company";
import ChangeDays from "@/components/vehicleAd/change-days";
import ChangeSticker from "@/components/vehicleAd/change-sticker.vue";
export default {
  components: {
    Layout,
    ChangeDays,
    ChangeSticker,
    AddCompanyModal,
    UpdateCompanyModal,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      fields: ["name", "email", "badge", "action"],
      items: [],
      loading: false,
      companyData: { name: "Mo" },
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      currentPage: 1,
      rows: 0,
      searchTerm: "",
    };
  },
  methods: {
    onRowClicked(item) {
      item._showDetails = true;
    },
    perPageChanged() {
      this.currentPage = 1;
      this.loadData();
    },
    pageChanged(val) {
      this.currentPage = val;
      this.loadData();
    },
    viewDrivers(data) {
      console.log(data);
      this.$router.push({
        path: `/vehicles-ad?companyId=${data.item._id}`,

      });
    },
    deleteCompany(data) {
      if (confirm("Are you sure you want to delete this company?")) {
        var companyId = data.item._id;
        const api = vehicle_company.delete;
        api.data = { company_id: companyId };
        this.generateAPI(api)
          .then(() => {
            this.loadData();
          })
          .finally(() => {});
        console.log("Thing was saved to the database.");
      }
    },
    openUpdateModal(data) {
      this.companyData = data.item;
      this.$refs["modal1"].show();
    },

    closeModal() {
      this.$refs["modal1"].hide();
    },
    dateChanged(id, is_current_ad, updated_days) {
      console.log(id, updated_days);
      this.items.map((item) => {
        if (item._id === id) {
          if (is_current_ad) {
            item.vehicles[0].current_ad.no_of_days = updated_days;
          } else {
            item.vehicles[0].upcoming_ad.no_of_days = updated_days;
          }
          return item;
        } else {
          return item;
        }
      });
    },
    loadData() {
      const api = vehicle_company.get;
      api.params = {
        limit: this.perPage,
        page: this.currentPage,
        search: this.searchTerm,
      };
      this.loading = true;

      this.generateAPI(api)
        .then((res) => {
          this.items = res.data.allCompaniesList;
          this.rows = res.data.total_no_of_documents;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async submitHandler(data) {
      if (data.term.trim()) {
        this.searchTerm = data.term;
        await this.loadData();
      }
    },
    refreshDriver() {
      this.searchTerm = "";
      this.loadData();
    },
    campaignStickerChanged(data) {
      //  this.items.vehicles[0].upcoming_ad
      this.items = this.items.map((item) => {
        if ((item._id = data._id)) {
          item.vehicles[0].upcoming_ad.campaign = data;
        }
        return item;
      });
    },
  },
};
</script>

<style>
.vehicle-ad {
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 12px;
}
.vehicle-ad .details-section {
  background-color: #fafafa;
  padding: 8px 14px;
  margin: 4px;
  border-radius: 4px;
}
</style>
